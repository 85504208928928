import { Body, H1, H5, Inline } from "@casavo/habitat"
import Link from "next/link"

import {
  heroListItem,
  heroContainer,
  leftHero,
  heroList,
  heroImage,
} from "@app/components/engineering/styles.css"
import { MyImage } from "@app/shared/components/MyImage"
import { container, wrapper } from "@app/shared/landingpages/styles.css"

export const Hero: React.FC = () => {
  const roles = [
    "Junior Software Engineer",
    "Software Engineer",
    "Senior Software Engineer",
    "Lead Software Engineer",
    "Staff Software Engineer",
  ]

  return (
    <>
      <section className={wrapper({ bg: "white" })}>
        <div className={[container, heroContainer].join(" ")}>
          <div>
            <div className={leftHero}>
              <MyImage
                alt="Casavo's logo"
                height={48}
                id="hero-title-icon-mobile"
                src={`/images/hq-logo/logo-casavo-main.svg`}
                width={240}
              />

              <hgroup>
                <H1>Tech Ladder</H1>
                <H5>
                  Our technical ladder is divided into <Inline strong>4 verticals</Inline>, covering what are
                  the expectations of every individual contributor according to their{" "}
                  <Inline strong>seniority</Inline>, which is specified as:
                </H5>
              </hgroup>

              <ul className={heroList}>
                {roles.map((role) => {
                  return (
                    <li className={heroListItem}>
                      <Link href={"#" + role.replace(/\s+/g, "-").toLocaleLowerCase()}>
                        <Inline underline>{role}</Inline>
                      </Link>
                      <svg fill="none" height="10" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                        <path
                          clipRule="evenodd"
                          d="M5.41421 0.292847L13.1213 7.99995L5.41421 15.7071L4 14.2928L10.2921 7.99995L4 1.70706L5.41421 0.292847Z"
                          fill="#1D1D1B"
                          fillRule="evenodd"
                        />
                      </svg>
                    </li>
                  )
                })}
              </ul>

              <Body size="s">
                Below is a definition of every vertical followed by the expectations of every level on each of
                the vertical. Every level contains a non-exhaustive list of examples to better clarify what's
                intended in practice to <em>check-off</em> that particular expectation.
              </Body>
            </div>
          </div>
          <div className={heroImage}>
            <MyImage alt="" height={500} src={`/images/engineering/hero-image.svg`} width={500} />
          </div>
        </div>
      </section>
    </>
  )
}
