import { Dispatch, SetStateAction, useEffect } from "react"

import Engineering from "@app/pages/engineering"

import { li } from "../blog-post/style.css"

import { closeModalButton, engineeringModal, modalCard, overflowHidden } from "./styles.css"

interface ModalProps {
  examples: string[]
  openModal: boolean
  setOpenModal: Dispatch<SetStateAction<boolean>>
}

export const Modal: React.FC<ModalProps> = ({ examples, openModal, setOpenModal }) => {
  useEffect(() => {
    document.body.classList.add(overflowHidden)
    return () => {
      document.body.classList.remove(overflowHidden)
    }
  }, [])

  return (
    <dialog
      className={engineeringModal}
      open={openModal}
      onClick={() => {
        setOpenModal((prevState: boolean) => !prevState)
      }}
    >
      <ul
        className={modalCard}
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        {examples.map((example, index) => {
          return <li key={index}>{example}</li>
        })}
        <div
          className={closeModalButton}
          onClick={() => {
            setOpenModal((prevState: boolean) => !prevState)
          }}
        >
          &#10005;
        </div>
      </ul>
    </dialog>
  )
}
