import { container, wrapper } from "@app/shared/landingpages/styles.css";
import { singleRole, singleTask, singleTaskLink, taskHeroImage, tasksContainer, tasksHero, tasksHeroArrow, tasksList } from "./styles.css";
import { Body, H1, H3 } from "@casavo/habitat";
import { MyImage } from "@app/shared/components/MyImage";
import { useState } from "react";
import { Modal } from "./Modal";

interface SingleCarouselProps {
  role: {
    bg: "white" | "offwhite";
    title: string;
    verticals: {
      image: string;
      title: string;
      caption: string;
      tasks: string[];
    }[];
    examples: string[];
  };
}


export const SingleCarousel: React.FC<SingleCarouselProps> = ({ role }) => {

  const [vertical, setVertical] = useState(0)
  const [openModal, setOpenModal] = useState<boolean>(false)

  return (
    <>
      <section className={wrapper({ bg: role.bg })} id={role.title.replace(/\s+/g, '-').toLocaleLowerCase()}>
        <div className={container}>


          <div className={singleRole}>
            <H1>{role.title}</H1>

            <div className={tasksContainer}>
              <div className={tasksHero}>
                {(vertical < 3) && <button onClick={() => { setVertical((prevVertical) => prevVertical + 1) }} className={tasksHeroArrow({ direction: "right" })}>
                  <MyImage alt="" height={50} src={`/images/engineering/next.svg`} width={50} />
                </button>}

                {(vertical > 0) && <button onClick={() => { setVertical((prevVertical) => prevVertical - 1) }} className={tasksHeroArrow({ direction: "left" })}>
                  <MyImage alt="" height={50} src={`/images/engineering/prev.svg`} width={50} />
                </button>}
                <MyImage className={taskHeroImage} alt="" height={280} src={role.verticals[vertical].image} width={280} />
                <div>
                  <H3>{role.verticals[vertical].title}</H3>
                  <Body>{role.verticals[vertical].caption}</Body>
                </div>

              </div>
              <ul className={tasksList}>
                {role.verticals[vertical].tasks.map((task, index) => {
                  return (
                    <li key={index} className={singleTask({ bg: "glass" })}>
                      <Body>{task}</Body>
                    </li>)
                })}

                <li onClick={() => { setOpenModal(prevState => !prevState) }} className={singleTask({ bg: "white" })}>
                  <a className={singleTaskLink} type="button">
                    <Body noMargin>Real life examples &rarr;</Body>
                  </a>
                </li>

                {openModal && <Modal openModal={openModal} setOpenModal={setOpenModal} examples={role.examples} />}


              </ul>
            </div>
          </div>
        </div>
      </section >
    </>
  );
}