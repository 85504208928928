import { Footer } from "@app/shared/components/layout/Footer"

import { Carousel } from "./Carousel"
import { Hero } from "./Hero"
import { Verticals } from "./Verticals"

export const EngineeringContent: React.FC = () => {
  return (
    <>
      <Hero />
      <Verticals></Verticals>
      <Carousel></Carousel>
      <Footer />
    </>
  )
}
