import { Body, H1, H3, H6 } from "@casavo/habitat"

import { MyImage } from "@app/shared/components/MyImage"
import { container, wrapper } from "@app/shared/landingpages/styles.css"

import { fixedRolesList, rolesList, singleRole, singleRolesListElement, singleTask, singleTaskLink, tasksContainer, tasksHero, tasksList } from "./styles.css"
import { flexCenter, flexColumn } from "@app/shared/design-lib/styles.css"
import { SingleCarousel } from "./SingleCarousel"
import Link from "next/link"
import { useEffect, useState, useRef } from "react"


interface Role {
  bg: "white" | "offwhite";
  title: string;
  verticals: {
    image: string;
    title: string;
    caption: string;
    tasks: string[];
  }[];
  examples: string[];
}

interface Data {
  roles: Role[];
}

interface SingleCarouselProps {
  data: Data;
}


const data: Data = {
  roles: [
    // Junior software engineer
    {
      bg: "white",
      title: "Junior Software Engineer",
      verticals: [
        {
          image: `/images/engineering/scope.svg`,
          title: "Scope of influence",
          caption: "Individual tasks within a project",
          tasks: [
            "Learning and being actively developed by others",
            "Tests new code thoroughly, locally, and check in production everything is working once shipped"
          ]
        },
        {
          image: `/images/engineering/building.svg`,
          title: "Building",
          caption: "Receives closer guidance and technical mentoring to avoid becoming blocked/stuck",
          tasks: [
            "Makes minor modifications to existing features, or minor changes to support client needs",
            "Delivers features requiring simple local modifications"
          ]
        },
        {
          image: `/images/engineering/executing.svg`,
          title: "Executing",
          caption: "Effectively delivers well-specced individual tasks",
          tasks: [
            "Completes well-specced tasks effectively, asking for help at the appropriate juncture to avoid becoming blocked/stuck",
            "Communicates project status clearly and effectively"
          ]
        },
        {
          image: `/images/engineering/leadership.svg`,
          title: "Leadership",
          caption: "You take ownership of your work and learning and share feedback for team or product improvement",
          tasks: [
            "Raises meaningful questions or concerns",
            "Takes ownership of learning and shows initiative"
          ]
        },
      ],
      examples: [
        "Develop knowledge of a single component of one of our services",
        "Catch a bug before it goes live in production",
        "Write tests reaching a high code coverage",
        "Tests properly the IaC scripts",
        "Implement a small improvement on a service",
        "Do a proper and fast database query",
        "Modify the settings/configuration of an existing infrastructure resource",
        "Add an existing UI component to a different part of the interface",
        "Effectively reuse an existing class to modify an existing API call",
        "Create a new automation pipeline based on an existing one",
        "Deliver a new method for an existing API",
        "Develop an asynchronous call to an existing API",
        "Add a new step to an existing CI pipeline",
        "Keep Jira up to date with progress and blockers and update teammates during stand up",
        "Voice concerns or need for clarification to their manager",
        "Accept and integrate constructive feedback effectively, objectively evaluating whether they've met their goals"
      ]

    },

    // Software engineer
    {
      bg: "offwhite",
      title: "Software Engineer",
      verticals: [
        {
          image: `/images/engineering/scope.svg`,
          title: "Scope of influence",
          caption: "A project overall within a tribe / product area. You work directly in parallel with your peers",
          tasks: [
            "Self-sufficient in primary area of work, contributing to the project as a whole",
            "Owns small-to-medium features from technical design through completion",
            "Works in parallel with peers, building a stronger team through effective collaboration"

          ]
        },
        {
          image: `/images/engineering/building.svg`,
          title: "Building",
          caption: "Develops new instances of existing architecture, or minor improvements to existing architecture",
          tasks: [
            "Clearly articulates code design choices that need to be made",
            "Makes large functional changes with uneventful code reviews",
            "Determines data needs from product requirements"
          ]
        },
        {
          image: `/images/engineering/executing.svg`,
          title: "Executing",
          caption: "Effectively delivers smaller features, making steady progress on tasks within the feature",
          tasks: [
            "Knows when to ask for help when they are becoming stuck; does not go down rabbit holes",
            "Balances pragmatism and polish appropriately",
            "Defines and hits slices of a bigger product increment"
          ]
        },
        {
          image: `/images/engineering/leadership.svg`,
          title: "Leadership",
          caption: "Co-owns an area with guidance & takes initiative (e.g fixes bugs unprompted). Self-directed learning process",
          tasks: [
            "Trusts teammates, assumes good intent in technical discussions, and is able to disagree and commit",
            "Has a self-directed learning process, seeks feedback from others to find ways to improve",
            "Makes design or quality improvements unprompted, making small improvements to leave code better than you found it"
          ]
        },
      ],
      examples: [
        "Debug effectively within their primary area to help find root cause",
        "Plan, scope and implement the 'bold' improvement in a feature involving a single service",
        "Plan and implement an improvement about an infrastructure component of a single service",
        "Proactively seek input from teammates and find ways to help teammates achieve their goals",
        "Identify the need for new index in a database table",
        "Advocate for a new UI component that could simplify the user interface",
        "Find a new improvement for a k8s configuration",
        "Build credit card input component that's functionally solid",
        "Code review feedback is focussed on growth and improvements",
        "Update the version of a dependency and the impacted codebase",
        "Ensure teammates are aware of current blockers. Pick cards or bugs off the backlog proactively when blocked elsewhere",
        "Engage with product management on feasibility of requested features, clarifying requirements where necessary"
      ]
    },

    // Senior Software engineer
    {
      bg: "white",
      title: "Senior Software Engineer",
      verticals: [
        {
          image: `/images/engineering/scope.svg`,
          title: "Scope of influence",
          caption: "A larger project overall within a tribe / product area. You work in parallel with peers and often take a leading role on features or refactors",
          tasks: [
            "Self-sufficient in at least one large area of the codebase with a high-level understanding of other components",
            "Contributes to the foundational good of their domain and engineering overall, defining (and refining) patterns and canonical examples, plus paying down tech debt",
            "Proactively develops a stronger team",
            "Mentors more junior members by helping them pursuing their commitments, and helping them grow in their career",
          ]
        },
        {
          image: `/images/engineering/building.svg`,
          title: "Building",
          caption: "Designs standalone systems of moderate complexity, or major new features in existing systems",
          tasks: [
            "Clearly articulates code design choices that need to be made, and suggests a viable path forward",
            "Acts as a primary maintainer for existing critical systems",
            "Implements complex features with a large product surface area"
          ]
        },
        {
          image: `/images/engineering/executing.svg`,
          title: "Executing",
          caption: "Effectively delivers features through a small team, consistently making meaningful progress",
          tasks: [
            "Consistently stays on track with work and keeps work in line with bigger picture goals",
            "Communicates technical issues and decisions clearly and proactively",
            "Recognizes and surfaces risks in developments or unknowns/gaps in requirements, at the scale of a few weeks long feature",
          ]
        },
        {
          image: `/images/engineering/leadership.svg`,
          title: "Leadership",
          caption: "Fully owns a service or component. Taking a considered approach to your work and your team",
          tasks: [
            "Shows maturity around what code not to write and thinking before you code, demonstrating critical thinking",
            "Identifies and suggests solutions to problems impacting team",
            "Exemplifies a “user first” mindset",
            "Is open to generalism and ready to actively collaborate with experts with other competences"
          ]
        },
      ],
      examples: [
        "Direct team response effectively during outages (able to be on-call independently or proactively address problems surfaced by advocates)",
        "Create our technical debt board and lead efforts to help teammates safely open source code",
        "Lead and coach within their team where possible and is trusted with team decisions",
        "Make others better through effective mentoring, pairing, and code reviews",
        "Give helpful and kind code reviews with actionable feedback about behaviour and maintainability",
        "Implement Google Auth login",
        "Deliver 'drag and drop' functionality for a web or mobile app",
        "Set realistic deadlines that drive effort and support healthy work habits. Cuts scope as needed, mitigating risk by shipping frequently",
        "Share bad news quickly. Express complex issues and concepts simply and clearly",
        "Ask good clarifying questions and successfully anticipate rabbit holes. Rarely need to re-work or revisit previous code",
        "Show curiosity and seeks to understand other points of view and alternate approach. Engage in productive dialog with conflicting views, both inside and outside the team",
        "Give actionable feedback to others and trust them to decide to what extent to incorporate it",
        "Promote a no-ego attitude to ideas and code, listening deeply to others and striving to do what's best for the user",
        "Push to remove roadblocks by working side by side on issues with developers on a different vertical"
      ]
    },

    // Lead Software engineer
    {
      bg: "offwhite",
      title: "Lead Software Engineer",
      verticals: [
        {
          image: `/images/engineering/scope.svg`,
          title: "Scope of influence",
          caption: "Whole team/Multiple teams",
          tasks: [
            "Gives guidance & unblocks others at minimum on their team",
            "Acts as a resource to unblock and enable the whole team",
            "Sought out by others as a mentor and provider of architectural guidance and kind coaching",
            "Routinely and consistently pushes the team forward",
            "Leads discussions in a respectful manner, unblocking the team by avoiding toxic behaviours"
          ]
        },
        {
          image: `/images/engineering/building.svg`,
          title: "Building",
          caption: "Builds complex, reusable architectures that pioneer best practices and enable engineers to work more effectively",
          tasks: [
            "Seeks design/architecture or specialized input when needed (and knows when it's needed)",
            "Makes good, informed decisions around technical debt and tradeoffs",
            "Reduces the complexity of projects/services/processes in order to get more done with less work",
            "Researches and leads adoption of new approaches, systems or technologies to stay current and strive for excellence on your team",
            "Exhibits excellent judgment regarding decisions across many aspects of the project, correctly balancing tactical risks vs time to market"
          ]
        },
        {
          image: `/images/engineering/executing.svg`,
          title: "Executing",
          caption: "Effectively delivers features through a large team, or with a significant amount of stakeholders or complexity",
          tasks: [
            "Translates rough ideas into clear, executable stories with discrete tasks",
            "Communicates with non-technical team members to refine stories and tasks",
            "Consistently and accurately recognizes and communicates key risks in developments",
            "Ensure communication with stakeholder happens without issues",
            "Manages dependencies on other components and teams",
          ]
        },
        {
          image: `/images/engineering/leadership.svg`,
          title: "Leadership",
          caption: "Has a consistent record of very strong leadership for their area",
          tasks: [
            "Recognizes patterns or process that hurt the effectiveness of your team or that don't serve your overall, longer term goals and effectively works towards resolutions",
            "Exemplifies grit/tenacity in the face of obstacles, distractions or long and challenging tasks",
            "Builds leaders within their team or domain, educates across domain",
            "Communicates efficiently and kindly, leading the team by example",
          ]
        },
      ],
      examples: [
        "Create and maintains the CI/CD environment for building apps",
        "Implement and teaches others an architectural or development pattern to help a team work more effectively across a wide area",
        "Make others better through mentoring, pairing, architecture reviews, and code reviews, exhibiting care and attention to their fellow engineer's growth",
        "Reach out to other teams within and beyond Casavo to share knowledge and implement better ways to work",
        "In the presence of blockers do not point fingers looking for the blame, instead takes a positive and constructive attitude, guiding the team towards the solution",
        "Suggests multiple viable approaches and clearly communicates risks, advantages, pros and cons (tradeoffs) for each approach in architecture proposals.",
        "Avoids subtle architectural mistakes when considering new systems",
        "Implements an effective testing framework that increases both code quality and team velocity",
        "Architects a new solution to reduce the technical debt, improve performance and developer experience",
        "Makes architectural decisions that eliminate entire classes of bugs",
        "Finds ways to deliver requested scope faster, helps prioritizing the backlog, in order to tackle tech debt proactively.",
        "Coordinates communication among team and stakeholders, including the right people at the right times",
        "Identifies, defines, and solves strategic problems, thinking holistically about the whole system",
        "Trusted to always share status with all stakeholders, and proactively remedy communication issues",
        "Lead the implementation of a big product feature for multiple audiences",
        "Trusted to de-escalate conflicts and build consensus between team members about technical matters",
        "Helps others maintain resilience in period of change / challenge",
        "Acts as a multiplier who shares knowledge and delegates to help others grow.",
        "Has a positive and friendly attitude, even in stressful situations"
      ]
    },

    // Staff Software engineer
    {
      bg: "white",
      title: "Staff Software Engineer",
      verticals: [
        {
          image: `/images/engineering/scope.svg`,
          title: "Scope of influence",
          caption: "Multiple teams/projects. Multiple product areas",
          tasks: [
            "Acts as a resource to unblock and enable teams across various areas and products",
            "Exhibits excellent judgment regarding decisions across teams",
            "Routinely and consistently pushes multiple teams forward"
          ]
        },
        {
          image: `/images/engineering/building.svg`,
          title: "Building",
          caption: "Sets strategic foundational direction for an engineering team / area",
          tasks: [
            "Defines long-term goals and ensures active projects are in service of them",
            "Defines the strategic vision for foundational work and supporting technologies, reducing complexity to get more done with less work",
            "Leads architecting new systems/technologies/processes to stay current and move the bottom line"
          ]
        },
        {
          image: `/images/engineering/executing.svg`,
          title: "Executing",
          caption: "Manages major company pushes delivered by multiple teams",
          tasks: [
            "Looked to as a model for balancing product/business and engineering concerns",
            "Successfully plans and delivers complex, multi-team or system, long-term features, including ones with external dependencies",
            "Your decisions that have positive, long term, wide ranging consequences"


          ]
        },
        {
          image: `/images/engineering/leadership.svg`,
          title: "Leadership",
          caption: "Exhibits ownership across the org - this person is a guardian of Casavo",
          tasks: [
            "Exhibits excellent judgment regarding decisions across many teams",
            "Instills and promotes a culture of learning and development within the entire engineering team",
            "Considers multiple product audiences context, business objectives and external constraints when planning"
          ]
        },
      ],
      examples: ["Gives pragmatic guidance that empowers teams to develop their own solutions",
        "Regularly accountable to architecture review outcomes and prioritization for their team, product or area",
        "Architects and guides implementation of shared component libraries that work for several teams",
        "Defines and drives a complete migration plan from a technology to another",
        "Defines and develops Casavo's continuous delivery strategy used across all product teams",
        "Defines microservices migration plan",
        "Delivers quarterly engineering objective including multiple product feature on time",
        "Manages a key legacy component dismission",
        "Manages technical migration to a new development paradigm",
        "Thoughtfully and effectively seeks wide context and builds buy-in for decisions affecting multiple stakeholders",
        "Empowers team members to develop themselves and coached others on how to become mentors too",
        "Identifies and eliminates single points of failure throughout the organization"
      ]
    }
  ]
}


export const Carousel: React.FC = () => {

  return (
    <>
      <div className={wrapper({ bg: "white" })}>
        <div className={container}>
          <ul className={rolesList}>
            <li className={singleRolesListElement}>
              <Link href="#junior-software-engineer">
                <H6>Junior Software Engineer</H6>
              </Link>
            </li>
            <li className={singleRolesListElement}>
              <Link href="#software-engineer">
                <H6>Software Engineer</H6>
              </Link>
            </li>
            <li className={singleRolesListElement}>
              <Link href="#senior-software-engineer">
                <H6>Senior Software Engineer</H6>
              </Link>
            </li>
            <li className={singleRolesListElement}>
              <Link href="#lead-software-engineer">
                <H6>Lead Software Engineer</H6>
              </Link>
            </li>
            <li className={singleRolesListElement}>
              <Link href="#staff-software-engineer">
                <H6>Staff Software Engineer</H6>
              </Link>
            </li>
          </ul>
        </div>
      </div>

      {
        data.roles.map((role) => {
          return (
            <SingleCarousel role={role}></SingleCarousel>
          )
        })
      }


    </>
  )
}
