import { Body, H2, Inline } from "@casavo/habitat"
import { useState } from "react"

import { MyImage } from "@app/shared/components/MyImage"
import { container, wrapper } from "@app/shared/landingpages/styles.css"

import {
  mobileVerticalList,
  singleVerticalCard,
  verticalCardCaption,
  verticalCardImage,
  verticalListElement,
  verticalsContainer,
  verticalsList,
} from "./styles.css"

interface Item {
  caption: string
  image: string
  title: string
}

interface Data {
  items: Item[]
}

export const Verticals: React.FC = () => {

  const data: Data = {
    items: [
      {
        caption:
          "How much impact the work you do has on Casavo as a whole. As you progress, the work you do has a bigger and bigger positive effect on the company",
        image: `/images/engineering/scope.svg`,
        title: "Scope of influence",
      },
      {
        caption: "How you embody and promote coding practices to ship high quality products and services",
        image: `/images/engineering/building.svg`,
        title: "Building",
      },
      {
        caption:
          "Effectiveness in getting things done. Delivering well-scoped development that meet their goals, on time, within constraints, and harmoniously",
        image: `/images/engineering/executing.svg`,
        title: "Executing",
      },
      {
        caption:
          "Showing an 'ownership mindset'. You challenge the status quo and effect positive organizational change outside of your mandated work",
        image: `/images/engineering/leadership.svg`,
        title: "Leadership",
      },
    ],
  }

  const [currentVertical, setCurrentVertical] = useState<number>(0)
  const prevIndex = currentVertical - 1
  const nextIndex = currentVertical + 1

  return (
    <section className={wrapper({ bg: "orange" })}>
      <div className={[container, verticalsContainer].join(" ")}>
        <ul className={verticalsList}>
          {data.items.map((item, index) => {
            return (
              <li
                key={index}
                className={verticalListElement({ state: currentVertical == index ? "active" : "base" })}
                onClick={() => setCurrentVertical(index)}
              >
                <Inline strong>{item.title}</Inline>
              </li>
            )
          })}
        </ul>

        <article className={singleVerticalCard}>
          <MyImage className={verticalCardImage} alt="scope" height={280} src={data.items[currentVertical].image} width={280} />
          <div className={verticalCardCaption}>
            <H2>{data.items[currentVertical].title}</H2>
            <Body size="l">{data.items[currentVertical].caption}</Body>
          </div>
        </article>

        <ul className={mobileVerticalList}>

          <li
            onClick={() => {
              setCurrentVertical((prevVertical) => prevVertical - 1)
            }}
          >
            {currentVertical != 0 && (<Inline strong>&larr; {data.items[prevIndex].title}</Inline>)}
          </li>

          {currentVertical != 3 && (
            <li
              onClick={() => {
                setCurrentVertical((prevVertical) => prevVertical + 1)
              }}
            >
              <Inline strong>{data.items[nextIndex].title} &rarr;</Inline>
            </li>
          )}
        </ul>
      </div>
    </section>
  )
}
