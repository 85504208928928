
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { GetStaticProps, GetStaticPropsContext, InferGetStaticPropsType } from "next"
import useTranslation from "next-translate/useTranslation"
import { FC } from "react"

import { EngineeringContent } from "@app/components/engineering"
import { Seo } from "@app/components/seo"
import { PageKind } from "@app/shared/types/pages"
import { Meta } from "@app/shared/types/seo"
import * as casavoPages from "@shared/utils/pages"

const Engineering: FC<InferGetStaticPropsType<typeof wrappedGetStaticProps>> = ({ seo }) => {
  const { t } = useTranslation("agent")
  const metaTags: Meta = {
    description:
      "Explore Casavo's technical ladder, outlining the roles and expectations for each level of our engineering team. Learn about the scope of influence, responsibilities, and career progression from Junior to Staff Software Engineer.",
    title: "Casavo | Tech Ladder",
  }

  return (
    <>
      <Seo
        canonical={seo.canonical}
        og={{
          description: metaTags.description,
          images: [
            { url: (process.env.NEXT_PUBLIC_IMAGE_PATH || "").concat("/images/seo/casavo-og-image.png") },
          ],
          site_name: "Casavo",
          url: seo.canonical,
        }}
        {...metaTags}
      />

      <EngineeringContent />
    </>
  )
}

const wrappedGetStaticProps = async (ctx: GetStaticPropsContext) => {
  if (ctx.locale === casavoPages.DEFAULT_LOCALE) {
    return {
      notFound: true,
      props: {} as never,
    }
  }

  const locales = casavoPages.countryLocales(ctx.locales as Locale[])
  const seoBuilder = casavoPages.seo(process.env.NEXT_PUBLIC_BASE_PATH as string)(PageKind.engineering)

  const seo = {
    alternates: seoBuilder.alternates(locales),
    canonical: seoBuilder.canonical(ctx.locale as Locale),
  }

  return {
    props: {
      seo,
    },
    revalidate: process.env.NEXT_TARGET_MODE !== "serverless" ? 86400 : undefined,
  }
}

 const _getStaticProps: GetStaticProps = wrappedGetStaticProps

export default Engineering


// @ts-ignore
    export async function getStaticProps(ctx) {
// @ts-ignore
        let res = _getStaticProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/engineering',
// @ts-ignore
              loaderName: 'getStaticProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  